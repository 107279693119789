<template>
  <b-card>
    <div>
      <validation-observer ref="provider">
        <b-form>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Name" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input
                    v-model.trim="form.name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Referrals" label-for="referrals">
                <validation-provider #default="{ errors }" name="referrals" rules="required|min_value:0">
                  <b-form-input
                    v-model.number="form.referrals"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Referrals"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Reward Multiplier" label-for="rewardMultiplier">
                <validation-provider #default="{ errors }" name="rewardMultiplier" rules="required|min_value:0">
                  <b-form-input
                    v-model.trim="form.rewardMultiplier"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Reward Multiplier"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
            Save
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required } from '@validations'
export default {
  name: 'LevelEdit',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      currencyId: null,
      levelInfo: '',
      form: {
        name: '',
        referrals: null,
        rewardMultiplier: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      levels: 'hold/levels',
    }),
  },
  mounted() {
    this.initState()
  },
  watch: {
    levels() {
      this.levelInfo = this.levels.filter(el => el.id === +this.$route.params.id).pop()
      this.form = this.levelInfo
    },
  },
  methods: {
    ...mapActions({
      fetchHoldLevels: 'hold/fetchHoldLevels',
      updateHoldLevels: 'hold/updateHoldLevels',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)

      if (!this.$route.params.id) return

      this.currencyId = this.$route.params.id
      this.fetchHoldLevels(this.currencyId)
    },

    update() {
      this.waitRequest(() => {
        return this.updateHoldLevels({ id: this.currencyId, form: this.form })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Level is updated',
                icon: 'PlusIcon',
                variant: 'info',
              },
            })
            this.$router.push('/hold-settings')
          })
          .catch(this.checkErrors)
      })
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      return this.update()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
